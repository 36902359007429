import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import './App.scss'

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))

// const ProtectedRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest} render={props => (
//       // TODO: check redirect on mobile
//       localStorage.getItem('token')
//         ? <Component {...props} />
//         : <Redirect to='/login' />
//     )}
//   />
// )

// class App extends Component {
//   render () {
//     return (
//       <HashRouter>
//         <React.Suspense fallback={loading()}>
//           <Switch>
//             <Route exact path='/login' name='Login Page' render={props => <Login {...props} />} />
//             <Route exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
//             <Route exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
//             <ProtectedRoute path='/' name='Home' render={props => <DefaultLayout {...props} />} />
//           </Switch>
//         </React.Suspense>
//       </HashRouter>
//     )
//   }
// }

const App = () => {
  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest} render={(props) => (
        localStorage.getItem('token')
          ? <DefaultLayout {...props} />
          : <Redirect to='/login' />
      )}
    />
  )
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path='/login' name='Login Page' render={props => <Login {...props} />} />
          <Route exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
          <Route exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
          <ProtectedRoute path='/' name='Home' />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
