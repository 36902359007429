import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/stable'
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

const { REACT_APP_API_URL } = process.env

const link = new HttpLink({
  uri: REACT_APP_API_URL
  // credentials: 'same-origin'
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      // handle errors differently based on its error code
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          localStorage.removeItem('token')
          // const headers = operation.getContext().headers
          // operation.setContext({
          //   headers: {
          //     ...headers,
          //     // authorization: getNewToken()
          //   }
          // })
          // return forward(operation)
      }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    errorLink,
    authLink,
    link
  ])
})

ReactDOM.render(<ApolloProvider client={client}><App /></ApolloProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
